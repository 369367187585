import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function RedirectPFP() {
  const query = useQuery();
  const tokenId = query.get("tokenId");
  const redirectUrl =
    process.env.REACT_APP_GRAPHQL_BASE_URL +
    "/auth/ociqPfpAuthHPID?tokenId=" +
    tokenId;
  window.location.href = redirectUrl;
}
