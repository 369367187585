import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import Fab from '@mui/material/Fab';
import createStyles from "@mui/styles/createStyles";
import { makeStyles } from 'tss-react/mui';
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RequestAssistanceDialog from "./RequestAssistanceDialog";

const useStyles = makeStyles()(theme =>
  createStyles({
    FabButtonStyle: {
      zIndex: "3001",
      color: "#fff",
      backgroundColor: "#0096d6",
      position: "fixed",
      maxWidth: "30px",
      bottom: "35px",
      right: theme.spacing(3),
      "& span": {
        display: "none"
      },
      "&:hover": {
        "& span": {
          display: "block"
        },
        gap: "6px",
        position: "fixed",
        fontSize: "13px",
        bottom: "35px",
        maxWidth: "fit-content",
        right: theme.spacing(3)
      }
    }
  }));
const RequestAssistanceButton = ({ data }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Fab
        color="primary"
        variant="extended"
        className={classes.FabButtonStyle}
        onClick={() => setOpen(true)}
      >
        <ContactSupportOutlinedIcon />
        <span>{t("requestAssistance.requestAssist")}</span>
      </Fab>
      {open && <RequestAssistanceDialog setOpen={setOpen} data={data} />}
    </>
  );
};
export default RequestAssistanceButton;
