import { Box, Link, Modal, Typography } from "@mui/material";
import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #ddd",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    textAlign: "center",
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: theme.spacing(2)
    }
  },
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  },
  typography: {
    marginTop: theme.spacing(2),
    fontSize: 16,
    color: theme.palette.text.primary
  },
  disclaimerText: {
    marginTop: theme.spacing(1),
    fontSize: 16,
    color: theme.palette.text.primary
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  }
}));

const LandingPage = () => {
  const { classes } = useStyles();

  return (
    <div>
      <Modal
        open={true}
        classes={{ backdrop: classes.backdrop }}
        disableAutoFocus
      >
        <Box className={classes.modalBox}>
          <Typography variant="h6" component="h2" color="error">
            This tool has been retired.
          </Typography>
          <Typography className={classes.typography}>
            Please use{" "}
            <Link
              href={`${process.env.REACT_APP_OCI_BASE_URL}`}
              target="_blank"
              className={classes.link}
            >
              OCI
            </Link>{" "}
            for internal view and dummy partner access within{" "}
            <Link
              href={`${process.env.REACT_APP_PARTNER_BASE_URL}`}
              target="_blank"
              className={classes.link}
            >
              IQ
            </Link>{" "}
            for partner view.
          </Typography>
          <Typography
            variant="body1"
            component="h2"
            className={classes.disclaimerText}
          >
            For OCI access issues, please log a Tech Direct ticket, and for
            Partner dummy accounts, please reach out to market PO.
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default withRouter(LandingPage);
