import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tooltip,
  Typography
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "tss-react/mui";
import { FUTURE_GA_MESSAGE } from "./constants";

const useStyles = makeStyles()(theme => ({
  buttonContained: {
    color: "#FFFFFF",
    backgroundColor: "#0096D6",
    "&:hover": {
      backgroundColor: "#0096D6",
      color: "#FFFFFF"
    }
  }
}));

const SmartSearchDialog = ({ rskuProps, setOpen }) => {
  const rskuInfo = useSelector(state => state.productSelector.rSKUInfo);
  const { classes } = useStyles();
  window.summarySection = () => (
    <summary-section
      state={window.state}
      dispatch="dispatch"
      type="searchSku"
      enqueue-snackbar="enqueueSnackbar"
      close-snackbar="closeSnackbar"
    />
  );
  const futureGAPartNumbers = rskuInfo?.futureGAProductInfo?.reduce(
    (acc, item) => {
      if (!item.partNumber.includes("#")) {
        acc.push(item.partNumber);
      }
      return acc;
    },
    []
  );
  const handleRequestNewSku = () => {
    handleClose();
    window.OneConfigEmbed.requestRSKU({ isRequestRSKU: true });
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog fullScreen open={true} onClose={handleClose}>
        <DialogContent
          style={{ padding: "0px", position: "relative" }}
          dividers
        >
          <Paper sx={{ p: 1 }} elevation={2}>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item>
                <Button
                  color="inherit"
                  variant="text"
                  onClick={handleClose}
                  aria-label="close"
                  startIcon={<ArrowBackIosNewRoundedIcon />}
                >
                  Return To Config
                </Button>
              </Grid>
              {rskuInfo.showRequestRSKU && (
                <Grid item>
                  <Tooltip
                    title={
                      futureGAPartNumbers?.length ? (
                        <>
                          <Typography>{FUTURE_GA_MESSAGE}</Typography>
                          <Typography sx={{ marginTop: 2 }}>
                            {futureGAPartNumbers.join(", ")}
                          </Typography>
                        </>
                      ) : (
                        ""
                      )
                    }
                    arrow
                  >
                    <span>
                      <Button
                        id="requestNewSku"
                        type="button"
                        color="primary"
                        onClick={handleRequestNewSku}
                        className={classes.buttonContained}
                        disabled={rskuInfo?.futureGAProductInfo?.length}
                        variant="contained"
                      >
                        REQUEST A NEW SKU
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Paper>
          <smart-search {...rskuProps} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SmartSearchDialog;
