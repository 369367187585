import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  headStyle: {
    fontSize: 15,
    fontWeight: "bold"
  },
  field: {
    "& .MuiTableCell-sizeSmall": {
      padding: "8px 15px 8px 8px"
    }
  }
}));

const CustomTable = ({ columns, data }) => {
  const { classes } = useStyles();
  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead className={classes.field}>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  classes={{ head: classes.headStyle }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((record, index) => (
              <TableRow key={`row-${index}`} className={classes.field}>
                {columns.map(column => {
                  const value = record[column.id];
                  return (
                    <TableCell
                      id={column.id}
                      key={column.id}
                      style={{ verticalAlign: "inherit" }}
                    >
                      {column.render ? column.render({ record }) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomTable;
