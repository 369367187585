export const GRAPHQL_BASE_URL = "https://dev-oneconfig-api.corp.hpicloud.net"
export const WARNING_PUP_UP_TIME = 28; // Idle user time
export const SESSION_WARNING_TIMEOUT = 120; // This 2 mins time will be used to show warning time to user in Idle
// export const AUTHENTICATION_TOKEN =
//   window.location.hostname === "localhost"
//     ? "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJRCI6IjlhYjAyYWIwLWQwMTUtMTFlYS04NTYxLTBhNThhOWZlYWMyYSIsImF1dGhvcml6ZWQiOnRydWUsImV4cCI6MTYxMTM4MDU2MCwicGVybWlzc2lvbnMiOlsiQWRtaW4iLCJNVVRBVElPTl9QRVJNSVNTSU9OIiwiUVVFUllfUEVSTUlTU0lPTiJdLCJyb2xlSUQiOiIwMDAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDAiLCJ1c2VySUQiOiIwMDAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDAiLCJ1c2VyTmFtZSI6ImFkbWluIn0.T31ZxbniE7cUyKZHbVxh7iJjSXEIJvSRRz0jZYgFS1k"
//     : window.location.hostname === "hpi.bigmachines.com"
//     ? window.userviceJwt
//     : window.localStorage.getItem("REACT_BASE_URL")
//     ? window.userviceJwtDEV
//     : window.userviceJwtITG;

export const CDN_BASE_URL = "https://d1cplb5u7vvhng.cloudfront.net/";
// internal
export const HPUID_LOGIN_URL = " https://login-itg.external.hp.com/as/authorization.oauth2?client_id=oneconfig-dev&response_type=code&scope=openid_group&redirect_uri=https://dev-oneconfig-api.corp.hpicloud.net/auth/ociqAuthUID";
// external or partner
export const HPID_LOGIN_URL = "https://directory.stg.cd.id.hp.com/directory/v1/oauth/authorize?response_type=code&client_id=Y2757m7GoTH2ZqyGoZ6RVcEn4aBc72BG&redirect_uri=https://dev-oneconfig-api.corp.hpicloud.net/auth/ociqAuthHPID&scope=openid+user.profile.read&locale=en-US&country=US&state=1234state";
export const API_BASE_URL = "https://dev-oneconfig-api.corp.hpicloud.net";

// Positions to change the order of panel and bom containers.
export const PANEL_CONTAINER_POSITION = 1;
export const BOM_CONTAINER_POSTION = 2;
export const USER_TOKEN = "eyJhbGciOiJIUzI1Nigit IsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN0YWN5LmJveWxlIiwicGFzc3dvcmQiOiIxIiwiZW1haWwiOiJzdGFjeS5ib3lsZUBocC5jb20iLCJyb2xlcyI6WyJJbnRlcm5hbCJdfQ.GZ6MPdYv9GuTIXy6DFA3JwUIovmojlK1gmQrmO9Wye4"

