import { ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/link-context";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme
} from "@mui/material/styles";
import createGenerateClassName from "@mui/styles/createGenerateClassName";
import Cookies from "js-cookie";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import configureStore from "./configureStore";
import i18n from "./i18n/index";
import {
  SnackbarUtilsConfigurator,
  StyledSnackbarProvider
} from "./lib/common/SnackBarUtils";
import * as serviceWorker from "./serviceWorker";
const { ApolloClient } = require("apollo-client");
const { InMemoryCache } = require("apollo-cache-inmemory");
const { createUploadLink } = require("apollo-upload-client");

const { store, persistor } = configureStore();

const authLink = setContext((_, { headers }) => {
  //token is stored in the cookie, hence fetching from there
  const token = Cookies.get("access_token");
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  };
});
const theme = createTheme();

const generateClassName = createGenerateClassName({
  productionPrefix: "iq"
});

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_GRAPHQL_BASE_URL}/model`,
  cache: new InMemoryCache({
    addTypename: false
  }),
  link: authLink.concat(
    createUploadLink({ uri: `${process.env.REACT_APP_GRAPHQL_BASE_URL}/model` })
  )
});
const container = document.getElementById(
  "attribute-prodSelectorReactContainer"
);
const root = createRoot(container);

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <StrictMode>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <I18nextProvider i18n={i18n}>
                <StyledSnackbarProvider generateClassName={generateClassName}>
                  <SnackbarUtilsConfigurator />
                  <App />
                </StyledSnackbarProvider>
              </I18nextProvider>
            </PersistGate>
          </Provider>
        </ApolloProvider>
      </StrictMode>
    </ThemeProvider>
  </StyledEngineProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
