const columns = [
  {
    id: "selectRow",
    label: "Select",
    minWidth: 20
  },
  {
    id: "configure",
    label: "",
    minWidth: 50
  },
  {
    id: "favorite",
    label: "Favorites",
    minWidth: 50
  },
  {
    id: "configID",
    label: "Config ID",
    minWidth: 70
  },
  {
    id: "configName",
    label: "Config Name",
    minWidth: 100
  },
  {
    id: "country",
    label: "Country",
    minWidth: 100
  },
  {
    id: "baseunit",
    label: "Base Unit",
    minWidth: 100
  },
  {
    id: "createdAt",
    label: "Date Created",
    minWidth: 100
  }
];

export default columns;
