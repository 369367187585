import { gql } from "apollo-boost";

//#query direct customer CRS / channel partner CRS
export const GET_CRS_DATA = gql`
  query getCrs($filter: CrsFilter!) {
    getCrs(filter: $filter) {
      ID
      accountName
      postal
      paymentTerm
      paymentTermDesc
      salesArea
      companyCode
      bpRole
      bpRoleDescription
      taxNumbers
      address {
        country
        state
        city
        street
      }
    }
  }
`;

export const GET_RESELLERS = gql`
  query resellers($filter: ResellerFilter!) {
    resellers(filter: $filter) {
      resellerID
      name
      city
      country
      postCode
      state
      street
      locationID
      agreementNumber
    }
  }
`;
export const GET_CTE = `
  query tax($filter: TaxFilter!) {
    tax(filter: $filter) {
      billingType
      totalTaxAmount
      lineItems {
        docNumber
        partNumber
        taxRate
        taxAmount
        extendedNetPriceWithTax
        ndpWithTax
        quantity
        hsnCode
        authorizedNet
      }
    }
  }
`;
export const GET_AUTO_ATTACHED = gql`
  query searchProductsPlus($filter: searchProductsInput!) {
    searchProductsPlus(filter: $filter) {
      sku
      name
      type
      listPrice
      category
      description
      hasError
      errorMessage
      overview
      images {
        httpUrl
        httpsUrl
        color
      }
      companions {
        sku
        name
        type
        listPrice
        description
        facetType
        category
        images {
          httpUrl
          httpsUrl
          color
        }
      }
    }
  }
`;

export const GET_SEARCH_PRODUCT = gql`
  query productHierarchy($filter: ProductHierarchyFilter!) {
    productHierarchy(filter: $filter) {
      label
      values
      children {
        label
        values
        children {
          label
          values
          children {
            label
            values
            children {
              label
              values
            }
          }
        }
      }
    }
  }
`;
export const GET_CUSTOMER_DATA = gql`
  query ($key: String!) {
    quoteCustomSettings(filter: { key: $key }) {
      id
      key
      value
      category
    }
  }
`;

export const GET_PROD_CAT_SERIES = gql`
  query searchProductCategories($countryCode: String!, $proCategory: String!) {
    searchProductCategories(
      countryCode: $countryCode
      proCategory: $proCategory
    )
  }
`;

export const GET_DEFAULT_CONFIGURATION = gql`
  query catalogLookup($filter: CatalogLookupRequest) {
    catalogLookup(filter: $filter)
  }
`;

export const CATALOG_LOOKUP = gql`
  query catalogLookup($filter: CatalogLookupRequest) {
    catalogLookup(filter: $filter)
  }
`;

export const GET_MODEL_CONFIGURATIONS = gql`
  query modelConfigurations($filter: CatalogLookupRequest) {
    modelConfigurations(filter: $filter)
  }
`;

export const GET_SEARCH_MY_CONFIGS = gql`
  query searchConfig($email: String) {
    searchConfig(email: $email)
  }
`;

export const GET_ALL_SEARCH_CONFIG = gql`
  query searchSavedConfig($searchConfigRequest: SearchConfigRequest!) {
    searchSavedConfig(searchConfigRequest: $searchConfigRequest)
  }
`;

//FIXME: modifiy the query once service ready
export const GET_BASE_UNIT_CONFIGS = gql`
  query searchConfigurationsOnBaseUnit($filter: ListConfigurationsFilter!) {
    searchConfigurationsOnBaseUnit(filter: $filter) {
      configURL
      name
    }
  }
`;

export const GET_PREAPPROVED_CONFIGS = gql`
  query preApprovedConfigs($filter: PreApprovedConfigsRequest!) {
    preApprovedConfigs(filter: $filter)
  }
`;

export const GET_DEAL_HEADER_LOOKUP = gql`
  query getDealHeaderLookup($filter: DealHeaderFilter!) {
    dealHeaderLookup(filter: $filter) {
      statusCode
      message
      lineItems {
        productNumber
        remainingQuantity
        productDescription
        listPrice
        configID
        authBdNet
        bdID
        lineTypeCode
        listPrice
        productOptCode
      }
    }
  }
`;

export const GET_PREVIEW_PRODUCTS = gql`
  query previewProducts($filter: searchProductsInput!) {
    previewProducts(filter: $filter) {
      description
      sku
      listPrice
      name
      isOveridden
      hasError
      images {
        pixelWidth
        color
        httpUrl
        httpsUrl
      }
      errorMessage
      category
    }
  }
`;

export const GET_INDICATIVE_LEASE_RATE = gql`
  query indicativeLeaseRate($filter: IndicativeLeaseRateFilter!) {
    indicativeLeaseRate(filter: $filter) {
      hardwarePCRate
      hardwarePrintRate
      servicePCRate
      servicePrintRate
    }
  }
`;

export const GET_QUOTE_LEASE_PRICE = gql`
  query quoteLeasePrice($filter: QuoteLeasePriceFilter!) {
    quoteLeasePrice(filter: $filter) {
      transactionResult {
        resultSuccess
        validation {
          validationId
          validationText
        }
      }
      leasePaymentOptions {
        leaseType
        leaseTerm
        paymentFrequency
        amount
      }
    }
  }
`;

export const SEND_LEASE_EMAIL = gql`
  mutation sendEmail($input: EmailInput!) {
    sendEmail(input: $input) {
      errorMsg
    }
  }
`;

export const GET_CONFIG_VALIDATIONS = gql`
  query configValidation($filter: [ConfigValidationFilter!]!) {
    configValidation(filter: $filter) {
      isSuccess
      message
    }
  }
`;
export const GET_PC_SEARCH_PRODUCT = gql`
  query productHierarchy($filter: ProductHierarchyFilter!) {
    productHierarchy(filter: $filter) {
      label
      values
      children {
        label
        values
      }
    }
  }
`;
export const GET_CONFIG_VALIDATION = `
  query configValidation($filter: [ConfigValidationFilter!]!) {
    configValidation(filter: $filter) {
      isSuccess
      message
    }
  }
`;
export const GET_LIGHT_PRICE = gql`
  query getPrice($filter: GetPriceFilter!) {
    getPrice(filter: $filter) {
      grandTotal
      totalTax
      totalNetPrice
      totalWithTax
      totalShippingFee
      totalRecyclingFee
      lineItems {
        discountPercent
        docNumber
        partNumber
        quantity
        recyclingFee
        shippingFees
        taxAmount
        taxRate
      }
    }
  }
`;
export const GET_JUSTIFICATION_FORM = gql`
  query dynamicForms($filter: DynamicFormFilter!) {
    dynamicForms(filter: $filter) {
      id
      countryCode
      fields {
        id
        name
        type
        placeholder
        dynamicFormID
        options
        sequence
        label
        isMandatory
        maxCharacters
        updatedAt
      }
    }
  }
`;
export const LIGHT_GET_PRICE = `query getPrice($filter: GetPriceFilter!) {
  getPrice(filter: $filter) {
    grandTotal
    totalTax
    totalNetPrice
    totalWithTax
    totalShippingFee
    totalRecyclingFee
    lineItems {
      discountPercent
      docNumber
      partNumber
      quantity
      recyclingFee
      shippingFees
      taxAmount
      taxRate
    }
  }
}
`;
//For fetching Countries in Config menu
export const GET_MENUITEM_COUNTRY = gql`
  query getMenuItems($filter: MenuItemFilter!) {
    getMenuItems(filter: $filter) {
      id
      value
      displayValue
      orderNumber
    }
  }
`;

export const GET_UPLOAD_CONFIG = gql`
  query {
    uploadConfiguration(
      filter: {
        transactionID: "8812919955"
        configurationIDESSN: "T000367147-0111262020075839"
      }
    ) {
      isSuccess
      message
    }
  }
`;

export const PRODUCT_SELECTION = gql`
  query {
    productSelection
  }
`;

export const GET_AUTH_TOKEN = gql`
  query ($email: String!) {
    getAuthToken(email: $email)
  }
`;

export const FETCH_USER_DETAILS = gql`
  query {
    fetchUserDetails
  }
`;

export const SAVE_CONFIGURATION = gql`
  mutation createConfiguration($input: NewSaveConfiguration!) {
    createConfiguration(input: $input)
  }
`;
export const SAVE_FAV = gql`
  mutation updateConfigurationStatus($input: ConfigStatusReq!) {
    updateConfigurationStatus(input: $input) {
      issuccess
      message
    }
  }
`;
export const CREATE_CASE_REQUEST_ASSISTANCE = gql`
  mutation RequestAssistance($input: RequestAssistanceInput!) {
    RequestAssistance(input: $input)
  }
`;