import { Box, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { useTranslation } from "react-i18next";
import CustomTable from "./CustomTable";

export default function AddNotesAttachmentsList({
  attachments,
  setAttachments
}) {
  const { t } = useTranslation();
  const handleDelete = record => {
    const filteredAttachments = attachments.filter(
      file =>
        !(
          file.fileName === record.fileName &&
          file.uploadedTime === record.uploadedTime
        )
    );
    setAttachments(filteredAttachments);
  };

  const columns = [
    {
      id: "fileName",
      label: <b>{t("requestAssistance.fileName")}</b>,
      render: ({ record }) => {
        return record.fileName ? (
          <a
            href={record.file}
            style={{ color: "black", textDecoration: "none" }}
            download={record.fileName}
          >
            <Typography sx={{ fontSize: "inherit" }}>
              {record.fileName}
              <IconButton color="primary" size="small">
                <SaveAltOutlinedIcon fontSize="small" />
              </IconButton>
            </Typography>
          </a>
        ) : (
          ""
        );
      }
    },
    {
      id: "createdAt",
      label: <b>{t("requestAssistance.createdAt")}</b>
    },
    {
      id: "userName",
      label: <b>{t("requestAssistance.userName")}</b>
    },
    {
      id: "action",
      label: <b>{t("requestAssistance.action")}</b>,
      render: ({ record }) => {
        return record.newRecord ? (
          <Typography color="primary" sx={{ fontSize: "inherit" }}>
            <IconButton color="primary" size="small">
              <DeleteIcon onClick={() => handleDelete(record)} />
            </IconButton>
          </Typography>
        ) : (
          ""
        );
      }
    }
  ];

  return (
    <>
      <Box border="solid #DCDCDC 1px" borderRadius="10px" p={3} my={3}>
        <CustomTable columns={columns} data={attachments} />
      </Box>
    </>
  );
}
