export const sortAlphabetically = (x, y) => {
  let a = x.displayValue.toUpperCase(),
    b = y.displayValue.toUpperCase();
  return a === b ? 0 : a > b ? 1 : -1;
};
//FIXME: needs code cleanup - quick fix
export const sortAlphabeticallyByName = (x, y) => {
  let a = x.name.toUpperCase(),
    b = y.name.toUpperCase();
  return a === b ? 0 : a > b ? 1 : -1;
};

export const getAttributeValue = elementId => {
  const element = document.getElementById(elementId);
  return element ? element.value : "";
};
