import Cookies from "js-cookie";
import { getClientToken } from "../actions/userActions";
import { isTokenExpired } from "../lib/common/util";

export const useGetRefreshedToken = () => {
  return async oldToken => {
    const access_token = Cookies.get("access_token");
    if (isTokenExpired(oldToken) && !isTokenExpired(access_token)) {
      try {
        const token = await getClientToken(access_token);
        return token;
      } catch (error) {
        throw new Error("Failed to get client token");
      }
    } else {
      return oldToken;
    }
  };
};
