import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Alert, Box, Typography } from "@mui/material";
import r2wc from "@r2wc/react-to-web-component";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetRefreshedToken } from "../../hooks/invokeRefreshToken";
import { getStorageValue } from "../../lib/common/util";
import { SET_RSKU_INFO } from "../../lib/constants";
import SmartSearchDialog from "./SmartSearchDialog";
import {
  CLOSE_MATCH,
  EXACT_MATCH,
  FUTURE_GA_MESSAGE,
  SUB_CLOSE_MATCH
} from "./constants";

const SmartSearchAddOn = props => {
  const { message, futureGaMessage, futureGaProducts } = props;
  if (message === "") return null;
  return (
    <div>
      <Alert severity="info">{message}</Alert>
      {futureGaProducts?.length > 0 && (
        <Box
          style={{
            display: "flex",
            border: "1px solid #0096d6",
            padding: "10px 15px",
            margin: "10px 0px",
            borderRadius: 5
          }}
        >
          <InfoOutlinedIcon color="primary" />
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              color="primary"
              style={{
                fontSize: "14px",
                marginBottom: "10px",
                paddingLeft: "10px"
              }}
            >
              {futureGaMessage}
            </Typography>

            <Box
              component={"ul"}
              style={{ margin: 0, paddingLeft: "40px", listStyle: "disc" }}
            >
              {futureGaProducts
                .filter(item => !item.partNumber.includes("#"))
                .map((each, index) => (
                  <Box
                    key={index}
                    component={"li"}
                    style={{ color: "#0096d6" }}
                  >
                    <Typography color="primary" style={{ fontSize: "12px" }}>
                      {`${each.partNumber}  -  ${each.description}`}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

const WebSmartSearchAddOn = r2wc(SmartSearchAddOn, {
  props: {
    message: "string",
    futureGaMessage: "string",
    futureGaProducts: "json"
  }
});
customElements.define("smart-search-add-on", WebSmartSearchAddOn);

const RSKU = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const rSKUInfo = useSelector(state => state.productSelector.rSKUInfo);
  const client_token = Cookies.get("client_token");
  const isInternal = getStorageValue("userEmail").includes("@hp.com");
  const headerTheme = isInternal ? "light" : "";
  const getRefreshedToken = useGetRefreshedToken();
  window.getRefreshedToken = getRefreshedToken;

  const message = rSKUInfo?.showSnackbar?.isExactMatch
    ? EXACT_MATCH
    : rSKUInfo?.showSnackbar?.isCloseMatch
    ? `${CLOSE_MATCH}
        ${!rSKUInfo?.disableRequestRSKU ? SUB_CLOSE_MATCH : ""}
      `
    : "";

  window.smartSearchAddOn = () => (
    <smart-search-add-on
      message={message}
      future-ga-message={FUTURE_GA_MESSAGE}
      future-ga-products={JSON.stringify(rSKUInfo?.futureGAProductInfo || [])}
    />
  );

  const rskuProps = {
    token: client_token,
    environment: process.env.REACT_APP_ENVIRONMENT,
    geo: rSKUInfo?.pricingGeo,
    currency: rSKUInfo?.currency || "USD",
    incoterms: rSKUInfo?.incoterms || "DDP",
    region: rSKUInfo?.regionCode || "NA",
    translations: JSON.stringify({}),
    "get-refreshed-token": "getRefreshedToken",
    "header-theme": headerTheme,
    "gradient-search-box": "false",
    "is-compact-search-box": "false",
    "summary-section": "summarySection",
    "hide-landing-page": "true",
    "rsku-items": JSON.stringify(
      rSKUInfo?.visibleSKUs?.length
        ? rSKUInfo?.visibleSKUs.slice(0, 380) // Limit to 380 SKUs due to performance issue
        : [""]
    ),
    "country-code": rSKUInfo?.countryCode || "US",
    "route-to-market": rSKUInfo?.routeToMarket || "Direct",
    "show-message": "showMessage",
    "language-code": rSKUInfo?.languageCode || "en",
    "channel-rtm": rSKUInfo?.userType || "Internal", // Internal or Partner
    "show-details-aware": true,
    "show-hwstock-availability": rSKUInfo?.routeToMarket !== "Direct",
    "show-auto-attach": true,
    "show-quantity-update": "false",
    "hide-header": "true",
    "hide-filter-categories": "true",
    "top-gap": "3.5em",
    "smart-search-add-on": "smartSearchAddOn"
  };
  useEffect(() => {
    const handleMessage = event => {
      try {
        const response =
          typeof event.data.rSKUInfo === "string"
            ? JSON.parse(event.data.rSKUInfo)
            : {};
        if (response.action === "showSmartSearch") {
          dispatch({
            type: SET_RSKU_INFO,
            payload: response.data
          });
          setOpen(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return open && <SmartSearchDialog rskuProps={rskuProps} setOpen={setOpen} />;
};

export default RSKU;
