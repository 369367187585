import { combineReducers } from "redux";
import manageMyConfig from "./ManageMyConfigReducers";
import productSelector from './ProductSelectorReducers';
import userReducer from "./userReducer";

export const reducers = {
  user: userReducer,
  productSelector,
  manageMyConfig
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
