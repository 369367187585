import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    zIndex: 99,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2, 1),
    borderRadius: "10px"
  },
  buttonCls: {
    float: "right",
    marginTop: "1em",
    fontSize: theme.spacing(2),
    color: "dodgerblue"
  },
  warningIcon: {
    marginLeft: "10px",
    color: "red",
    position: "absolute"
  }
}));

const WarningPopup = props => {
  const { classes } = useStyles();
  const { open, headerTitle, handleClose, handleBtnClick, children } = props;

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      disableEscapeKeyDown
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2>
            {headerTitle}
            <ErrorOutlineOutlinedIcon
              fontSize="large"
              className={classes.warningIcon}
            ></ErrorOutlineOutlinedIcon>
          </h2>
          {children}
          <Button
            className={classes.buttonCls}
            color="primary"
            onClick={() => handleBtnClick()}
          >
            CONTINUE
          </Button>
        </div>
      </Fade>
    </Modal>
  );
};

export default WarningPopup;
