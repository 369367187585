import jwt_decode from "jwt-decode";
import moment from "moment";

export const getPlaceholder = (t, selectedValue) => {
  switch (selectedValue) {
    case "None":
      return t("common:manageMyConfig.textMessage.pleaseEnterNone");
    case "configID":
      return t("common:manageMyConfig.textMessage.pleaseEnterConfigID");
    case "configName":
      return t("common:manageMyConfig.textMessage.pleaseEnterConfigName");
    case "email":
      return t("common:manageMyConfig.textMessage.pleaseEnterEmail");
    case "baseUnit":
      return t("common:manageMyConfig.textMessage.pleaseEnterBaseUnit");
    default:
      return;
  }
};

export const getStorageValue = elementId => {
  const element = JSON.parse(localStorage.getItem("bmiData"));
  return element ? element[elementId] : "";
};

export const isTokenExpired = token => {
  if (!token) return true;
  const parsedToken = jwt_decode(token);
  const isExpired = moment.unix(parsedToken.exp).diff(moment(), "seconds") < 1;
  return isExpired;
};

export const addScript = (cdnUrl, id) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) return resolve(id);

    const script = document.createElement("script");
    script.setAttribute("id", id);
    script.setAttribute("src", cdnUrl);
    script.onload = () => resolve(id);
    script.onerror = err => reject(`${err} ${id}`);
    document.body.appendChild(script);
  });
};
