export const SET_EXPAND_COLLAPSE = "SET_EXPAND_COLLAPSE";
export const SET_EXPAND_COLLAPSE_PANEL = "SET_EXPAND_COLLAPSE_PANEL";
export const CONFIG_STEP_SELECTION = "CONFIG_STEP_SELECTION";
export const SET_CONFIG_LOAD_MODEL = "SET_CONFIG_LOAD_MODEL";
export const LOAD_CONFIG_STEPPER = "LOAD_CONFIG_STEPPER";
export const WEB_ASSEMBLY_UPDATE_MODEL = "WEB_ASSEMBLY_UPDATE_MODEL";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

export const TOKEN_VERIFY_REQUEST = "TOKEN_VERIFY_REQUEST";
export const TOKEN_VERIFY_SUCCESS = "TOKEN_VERIFY_SUCCESS";
export const TOKEN_VERIFY_FAIL = "TOKEN_VERIFY_FAIL";

export const TOKEN_RENEW_REQUEST = "TOKEN_RENEW_REQUEST";
export const TOKEN_RENEW_SUCCESS = "TOKEN_RENEW_SUCCESS";
export const TOKEN_RENEW_FAIL = "TOKEN_RENEW_FAIL";

export const RECEIVE_ACCESS_TOKEN = "RECEIVE_ACCESS_TOKEN";
export const CLEAR_USER_INFO = "CLEAR_USER_INFO";

export const CONFIG_USER_LOGOUT = "CONFIG_USER_LOGOUT";
export const SET_FILTERED_CHOICES = "SET_FILTERED_CHOICES";
export const SET_FILTERED_LOC = "SET_FILTERED_LOC";

export const SET_CONFIG_BOM = 'SET_CONFIG_BOM';
export const SET_SHOW_BOM_SECTION_FLAG = 'SET_SHOW_BOM_SECTION_FLAG';
