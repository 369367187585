import moment from "moment";
import {
  SET_COUNTRY, SET_MANAGE_MY_CONFIG_RESULTS_LIST, SET_RESET_PAGE, SET_SEARCH_KEY, SHOW_MANAGE_MY_CONFIG_RESULTS
} from "../models/configuration/manageMyConfig/Constants";
import configColumns from "./../config";
const { manageMyConfigColumns } = configColumns;
const manageMyConfig = (
  state = {
    columns: manageMyConfigColumns,
    countryCode: "",
    searchValue: "",
    manageMyConfigResultList: [],
    showManageMyConfigResults: false,
    page: 0
  },
  action
) => {
  switch (action.type) {
    case SET_SEARCH_KEY:
      return {
        ...state,
        searchValue: action.payload
      };
    case SET_COUNTRY:
      return {
        ...state,
        countryCode: action.payload
      };
    case SET_MANAGE_MY_CONFIG_RESULTS_LIST:
      const sorted = action.payload
        ? action.payload
            .sort((a, b) => {
              return b.configID - a.configID;
            })
            .sort((a, b) => {
              return (
                moment(b.createdAt).format("YYYYMMDD") -
                moment(a.createdAt).format("YYYYMMDD")
              );
            })
        : [];
      //Added code to display Favorite Configs first
      const favSorted = sorted.filter(ctry => ctry.favorite);
      const nonFavSorted = sorted.filter(ctry => !ctry.favorite);
      const newSorted = [...favSorted, ...nonFavSorted];
      state.columns = manageMyConfigColumns;
      return {
        ...state,
        manageMyConfigResultList: newSorted
      };
    case SHOW_MANAGE_MY_CONFIG_RESULTS:
      return {
        ...state,
        showManageMyConfigResults: action.payload
      };
    case SET_RESET_PAGE:
      return {
        ...state,
        page: action.payload
      };
    default:
      return state;
  }
};
export default manageMyConfig;