import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  cs_CZ,
  de,
  el_GR,
  en,
  es,
  fr,
  hu_HU,
  in_ID,
  it,
  ko_KR,
  nl_NL,
  pl_PL,
  pt,
  ru_RU,
  sk_SK,
  th_TH,
  tr_TR,
  vi_VN,
  zh_CN,
  zh_TW
} from "./locales";

const options = {
  interpolation: {
    escapeValue: false // not needed for react!!
  },

  resources: {
    pt: {
      common: pt["pt-BR"]
    },
    en: {
      common: en.en
    },
    zh_CN: {
      common: zh_CN.zh_CN
    },
    fr: {
      common: fr.fr
    },
    de: {
      common: de.de
    },
    cs_CZ: {
      common: cs_CZ.cs_CZ
    },
    es: {
      common: es.es
    },
    it: {
      common: it.it
    },
    pl_PL: {
      common: pl_PL.pl_PL
    },
    sk_SK: {
      common: sk_SK.sk_SK
    },
    tr_TR: {
      common: tr_TR.tr_TR
    },
    el_GR: {
      common: el_GR.el_GR
    },
    hu_HU: {
      common: hu_HU.hu_HU
    },
    nl_NL: {
      common: nl_NL.nl_NL
    },
    ko_KR: {
      common: ko_KR.ko_KR
    },
    ru_RU: {
      common: ru_RU.ru_RU
    },
    vi_VN: {
      common: vi_VN.vi_VN
    },
    in_ID: {
      common: in_ID.in_ID
    },
    th_TH: {
      common: th_TH.th_TH
    },
    zh_TW: {
      common: zh_TW.zh_TW
    }
  },

  fallbackLng: "en",

  // lng: window._BM_USER_LANGUAGE ? window._BM_USER_LANGUAGE : window.localStorage.getItem('i18nextLng'),

  ns: ["common"],

  defaultNS: "common",

  react: {
    wait: false,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default"
  }
};

i18n.use(LanguageDetector).init(options);

export default i18n;
