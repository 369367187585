export const SET_PRODUCT_CATEGORIES = "SET_PRODUCT_CATEGORIES";
export const SET_PRODUCT_SERIES = "SET_PRODUCT_SERIES";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_SELECTED_SERIES = "SET_SELECTED_SERIES";
export const SET_ALL_SEARCH_CONFIG = "SET_ALL_SEARCH_CONFIG";
export const SET_COUNTRY = "SET_COUNTRY";
export const SET_PROGRAM_TYPE = "SET_PROGRAM_TYPE";
export const SET_RESULTS_LIST = "SET_RESULTS_LIST";
export const SET_SEARCH_BASE_UNIT = "SET_SEARCH_BASE_UNIT";
export const SET_SHOWSERIES = "SET_SHOWSERIES";
export const SET_RSKU_INFO = "SET_RSKU_INFO";
export const PROCESS_ID = "4653759";
export const DOCUMENT_ID = "4653823";
export const VERSION_ID = "4748762";
export const ACTION_ID = "4654396";
export const SET_SEARCH_KEY = "SET_SEARCH_KEY";
export const SET_DISABLE = "SET_DISABLE";
export const FETCH_PRODUCT_SELECTION = "FETCH_PRODUCT_SELECTION";
export const CONFIG_PERSIST_DATA = "CONFIG_PERSIST_DATA";
export const REGIONS = {
  EU: "EMEA",
  EA: "EMEA",
  AP: "APJ",
  NA: "AMS",
  WW: "WW"
};
export const MAX_NUM_OF_ATTACHMENTS = 5;
export const MAX_ATTACHMENT_SIZE = 15700000;
export const ISSUE_REASONS = [
  {
    value: "Request Assistance - Configurations",
    label: "Configuration"
  }
];
