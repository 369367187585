//This function will return a boolean based on userType & configRegion and will determine
//if monitor and accessories tab should be made visible or not
export const fetchMonitorAccessoriesTabFlag = (userType, configRegion) => {
  let flag = false;
  if (userType === "INTERNAL" && configRegion === "AP") {
    flag = true;
  } else if (userType === "PARTNER" && configRegion === "AP") {
    flag = true;
  }
  return flag;
};

//This function will return a boolean based on userType & configRegion and will determine
//if ConfigurationServices tab should be made visible or not
export const fetchConfigurationServicesTabFlag = (userType, configRegion) => {
  let flag = true;
  if (userType === "PARTNER" && configRegion === "EMEA") {
    flag = false;
  } else if (userType === "PARTNER" && configRegion === "EU") {
    flag = false;
  }
  return flag;
};

//This function will return a boolean based on userType & configRegion and will determine
//if Manageability services tab should be made visible or not
export const fetchManageabilityServicesTabFlag = (userType, configRegion) => {
  let flag = true;
  if (userType === "PARTNER" && configRegion === "EMEA") {
    flag = false;
  } else if (userType === "PARTNER" && configRegion === "EU") {
    flag = false;
  }
  return flag;
};
